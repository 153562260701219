<template>
  <div id="mian">
    <div>
      <div class="content">
        <div class="desc">
          <h5 class="jsxx">退款信息</h5>
          <div>
            <el-button v-if="false" class="reEmailBt" type="primary"
              >重发邮件</el-button
            >
          </div>
        </div>
        <ul class="listBox">
          <li>
            <span>退款订单号</span
            ><i class="hidden1">{{
              detail.mchRefundNo ? detail.mchRefundNo : ""
            }}</i>
          </li>
          <li>
            <span>退款流水号</span
            ><i class="hidden1">{{
              detail.plfRefundNo ? detail.plfRefundNo : ""
            }}</i>
          </li>
          <li>
            <span>三方退款订单号</span
            ><i class="hidden1">{{
              detail.upsRefundNo ? detail.upsRefundNo : ""
            }}</i>
          </li>
          <li>
            <span>分账退款流水号</span
            ><i class="hidden1">{{
              detail.allocRefundTrxNo ? detail.allocRefundTrxNo : " "
            }}</i>
          </li>
          <li>
            <span>分账订单号</span
            ><i class="hidden1">{{
              detail.mchAllocOrderNo ? detail.mchAllocOrderNo : ""
            }}</i>
          </li>
          <li>
            <span>分账流水号</span
            ><i class="hidden1">{{
              detail.allocTrxNo ? detail.allocTrxNo : ""
            }}</i>
          </li>
          <li>
            <span>商户名称</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.mchName"
                placement="top"
              >
                <b>{{ detail.mchName }}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>商户编号</span
            ><i class="hidden1">{{ detail.mchId ? detail.mchId : "" }}</i>
          </li>
          <li>
            <span>分账商户名称</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.allocMchName"
                placement="top"
              >
                <b>{{ detail.allocMchName }}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>分账商户编号</span
            ><i class="hidden1">{{
              detail.allocMchId ? detail.allocMchId : ""
            }}</i>
          </li>
          <li>
            <span>店铺名称</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.storeName"
                placement="top"
              >
                <b>{{ detail.storeName }}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>店铺编号</span
            ><i class="hidden1">{{ detail.storeId ? detail.storeId : "" }}</i>
          </li>
          <li>
            <span>报备商户</span
            ><i class="hidden1">{{
              detail.tradeMchName ? detail.tradeMchName : ""
            }}</i>
          </li>
          <li>
            <span>报备商户号</span
            ><i class="hidden1">{{
              detail.tradeMchNo ? detail.tradeMchNo : ""
            }}</i>
          </li>
          <li>
            <span>代理商名称</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.agentName"
                placement="top"
              >
                <b>{{ detail.agentName }}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>服务商名称</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.servicerName"
                placement="top"
              >
                <b>{{ detail.servicerName }}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>所属机构</span
            ><i class="hidden1">{{ detail.orgName ? detail.orgName : "" }}</i>
          </li>
          <li>
            <span>渠道商户号</span
            ><i class="hidden1">{{
              detail.channelMchNo ? detail.channelMchNo : ""
            }}</i>
          </li>
          <li>
            <span>创建时间</span
            ><i class="hidden1">{{
              detail.createTime ? detail.createTime : ""
            }}</i>
          </li>
          <li>
            <span>退款时间</span
            ><i class="hidden1">{{
              detail.refundTime ? detail.refundTime : ""
            }}</i>
          </li>
          <li>
            <span>产品类型</span
            ><i class="hidden1">{{
              detail.productName ? detail.productName : ""
            }}</i>
          </li>
          <li>
            <span>退款状态</span
            ><i class="hidden1">{{ refundStatus[detail.refundStatus] }}</i>
          </li>
          <li>
            <span>交易金额</span
            ><i class="hidden1">{{
              (detail.orderAmount / 100) | formatMoney
            }}</i>
          </li>
          <li>
            <span>交易退款金额</span
            ><i class="hidden1">{{
              (detail.refundAmount / 100) | formatMoney
            }}</i>
          </li>
          <li>
            <span>营销金额</span
            ><i class="hidden1">{{
              (detail.marketingFee / 100) | formatMoney
            }}</i>
          </li>
          <li>
            <span>分账退款金额</span
            ><i class="hidden1">{{
              (detail.allocRefundAmount / 100) | formatMoney
            }}</i>
          </li>
          <li>
            <span>分账类型</span
            ><i class="hidden1">{{ allocFundType[detail.allocFundType] }}</i>
          </li>
          <li>
            <span>通知地址</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.notifyUrl"
                placement="top"
              >
                <b>{{ detail.notifyUrl }}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>响应码</span
            ><i class="hidden1">{{
              detail.resultCode ? detail.resultCode : ""
            }}</i>
          </li>
          <li>
            <span>响应码描述</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.resultMessage"
                placement="top"
              >
                <b>{{ detail.resultMessage }}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>异常码</span
            ><i class="hidden1">{{
              detail.errorCode ? detail.errorCode : ""
            }}</i>
          </li>
          <!-- <li><span>退款状态</span><i>{{refundStatus[detail.refundStatus]}}</i></li> -->
          <li>
            <span>异常描述</span
            ><i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.errorMessage"
                placement="top"
              >
                <b>{{ detail.errorMessage }}</b>
              </el-tooltip>
            </i>
          </li>
          <!-- <li><span>退款申请状态</span><i>2013423343245</i></li> -->
        </ul>
      </div>
      <div class="desc">
        <h5 class="jsxx">退款通知信息</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>通知时间</p>
            </td>
            <td>
              <p>请求类型</p>
            </td>
            <td>
              <p>通知状态</p>
            </td>
            <td>
              <p>通知结果</p>
            </td>
            <td>
              <p>状态码</p>
            </td>
            <td>
              <p>返回信息</p>
            </td>
          </tr>
          <tr v-for="(v, i) in notices" :key="i">
            <td>
              <p>{{ v.notifyTime }}</p>
            </td>
            <td>
              <p>{{ notifyType[v.notifyType] }}</p>
            </td>
            <td>
              <p>{{ v.reachStatus ? "已到达" : "未响应" }}</p>
            </td>
            <td>
              <p>{{ notifyStatus[v.notifyStatus] }}</p>
            </td>
            <td>
              <p>{{ v.resultCode }}</p>
            </td>
            <td>
              <p>{{ v.resultMessage }}</p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { getRefundsDetail } from "@/api/trading/subaccount.js";
export default {
  data() {
    return {
      detail: "",
      notices: [],
      crumbs: "交易详情",
      refundStatus: {
        WAIT: "退款中",
        REJECT: "已驳回",
        SUCCESS: "退款成功",
        FAIL: "退款失败",
        PROCESSING: "退款中",
        PASS: "审核通过",
      },
      allocFundType: {
        11: "实时分账",
        12: "延迟分账",
        13: "多次分账",
      },
      notifyType: {
        PLATFORM: "平台",
        THIRD: "第三方",
      },
      notifyStatus: {
        SUCCESS: "成功",
        FAIL: "失败",
      },
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    // 获取详情
    getDetail() {
      getRefundsDetail(this.$route.query.id).then((res) => {
        if (res) {
          this.detail = res.resultData.detail;
          this.notices = res.resultData.notices;
        }
      });
    },
    //菜单切换
    navClick(data) {
      this.crumbs = data;
    },
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.tab1{
  min-height: auto !important;
}


.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  width: 490px;
  float: left;
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul-left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
</style>
